<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'name'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields="['id', 'name', 'code', 'symbol', 'is_default']"
    >
        <template #columns>
            <Column field="name" :header="$t('overview.name')"></Column>
            <Column field="code" :header="$t('overview.code')" style="width: 20%"></Column>
            <Column field="symbol" :header="$t('overview.symbol')" style="width: 20%"></Column>
            <Column field="is_default" :header="$t('form.default')" style="width: 10%">
            <template #body="slotProps">
                <i class="fa fa-check text-success" v-show="slotProps.data.is_default"></i>
            </template></Column>
        </template>

        <template #form>
            <CurrencyForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import CurrencyForm from '@/components/currency/CurrencyForm.vue';
import currencyService from '@/services/CurrencyService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        CurrencyForm
    },
    data() {
        return {
            item: {},
            itemService: currencyService,
            itemFormRef: null,
            is_default: null
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>