<template v-if="formActive">
    <FormItem id="name" :label="$t('overview.name')" v-model="currency.name" :required="true" />
    <FormItem id="code" :label="$t('overview.code')" v-model="currency.code" />
    <FormItem id="symbol" :label="$t('overview.symbol')" v-model="currency.symbol" />
    <FormItem id="default" :label="$t('form.default')" v-model="currency.is_default" type="checkbox" />
</template>

<script>
    export default {
        name: 'CurrencyForm',
        props: ['itemData'],
        data() {
            return {
                currency: {},
                submitted: false,
                loading: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.currency = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let valid = true;
                let required = document.querySelectorAll('#formModal input[required],#formModal select[required]');

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(valid) valid = this.currency.name != '';
                if(!valid) alert(this.$t('errors.empty_fields'));
                return valid;
            },
            getData() {
                return this.currency;
            },
            setData(data) {
                this.currency = data
            },
            reset() {
                this.currency = {
                    'name': '',
                    'code': '',
                    'symbol': '',
                    'is_default': false
                },
                this.loading = false
                this.invalid = {}
            }
        }
    }
</script>
